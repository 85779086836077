@import './variables.scss';
@import './metronic-theme.scss';

body {
    /*Body & Soul Only*/
    .brand {
        background-color: #ffffff !important;
        
        .brand-logo {
            img {
                max-height: 50px;
            }
        }
    }

    .table-hide-action-title {
        table {
            thead {
                tr th:last-child {
                    color: transparent !important;
                }
            }
        }
    }

    .custom-control.custom-radio {
        input {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    /*Body & Soul Only*/
}