@import "@styles/variables.scss";

#kt_aside_menu {
    .facade-avatar, .facade-title, .facade-description > ul > li {
        background: linear-gradient(
        90deg
        , $primary-hover 24%, $primary 38%, $primary-hover 65%);
            background-size: 400% 100%;
        }
        
    .menu-nav {
        padding: 5px 0 !important;

        .sub-menu-icon {
            margin-left: 4px;
        }

        > .menu-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 25px;
            margin: 0 !important;
            
            cursor: pointer;

            .menu-text {
                color: $menu-text-color;
            }

            &[aria-expanded="true"] {
                .menu-icon {
                    color: $menu-icon-color;
                }
                > .fa-angle-right {
                    transform: rotate(90deg);
                }
            }

            .menu-icon {
                transition: transform 150ms ease-in-out;
                display: flex;
                font-size: 1.2rem;
                width: 25px;
                color: $menu-icon-color;
            }

            &:hover, &.menu-section-active {
                background-color: $primary-hover;
            }
        }
    } 
}