@font-face {
    font-family: 'HouschkaRounded-Light';
    src: url('https://bodyandsoulcharity.org/wp-content/fonts/34B1F3_0_0.woff2') format('woff2'),
         url('https://bodyandsoulcharity.org/wp-content/fonts/34B1F3_0_0.woff') format('woff');}
$pink: #FE0072;

.referral-form {
    min-height: 100vh;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0px 15px 75px;
    clear: both;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: 'HouschkaRounded-Light', 'Roboto', sans-serif !important;

    @media screen and (min-width: 820px) {
        padding: 0px 50px 75px;
    }

    .referral-form-header {
        background-repeat: no-repeat;
        background-image: url(../../../assets/images/becoming-a-member-bg.jpeg);
        background-attachment: scroll;
        background-position: center center;
        background-size: cover !important;
        margin: 0 -15px 0 0;

        @media screen and (min-width: 820px) {
            margin: 0;
        }

        .become-member {
            width: 55%;
            height: auto;
            margin-top: 63px;
            margin-bottom: 50px;
        }

        .thank-you-sign {
            font-size: 55px;
            font-family: fantasy;
            padding-top: 120px;
            padding-bottom: 80px;
            color: #358077;

            @media screen and (min-width: 600px) {
                font-size: 105px;
            }

            @media screen and (min-width: 820px) {
                font-size: 125px;
            }
        }
    }
    
    fieldset {
        legend {
            font-size: calc(13px + 0.7vw) !important;
        }
        color: #818181;
        margin-bottom: 35px;
    }

    .grid-field {
        overflow: auto;

        table {
            min-width: 750px;
        }

        .action-column {
            color: #fff;
        }
    }

    .table {
        thead th, tbody td {
           font-weight: 400;
           font-size: calc(9px + 0.7vw) !important;
           border-bottom-width: 1px;
        //    padding-top: 1rem;
        //    padding-bottom: 1rem;
           color: #818181;
           // word-break: break-all;
   
       }
    }

    p {
        color: #818181;
        font-size: calc(13px + 0.7vw) !important;
    }

    .action-cell {
        .btn {
            color: #818181 !important;
        }
    }

    .spread-sheet {

        .custom-control {
            height: 100%;

            &:not(.custom-switch) .custom-control-label::before, &:not(.custom-switch) .custom-control-label::after {
                top: 2px;
                left: -15px;

                @media screen and (min-width: 820px) {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }

            .custom-control-input:checked ~ .custom-control-label::before {
                background-color: $pink;
                border-color: $pink;
            }
        }

        input, .muted-data {
            padding-left: 10px;
            color: #818181;
            font-size: calc(10px + 0.7vw) !important;
        }

        table td [data-editable], table td {
            background-color: #f8f8f8;

            input {
                background-color: #f8f8f8;
            }
        }

        .react-datepicker-wrapper, .react-datepicker__input-container {
            height: 100%;

            input {
                background: #f8f8f8;
            }
        }

        .action-column {
            width: calc(80px + 0.7vw) !important;
            color: #fff;
        }

        
    }

    .referral-form-title {
        margin-top: 55px;
        margin-bottom: 25px;
        color: $pink;
        font-size: calc(24px + 0.7vw);
        line-height: 1.35em;
        clear: both;
        font-weight: 400;
    }

    .divider {
        min-height: 20px;
        height: 20px;
        margin: 0 -15px;
        background-repeat: repeat;
        background-position: 0 0;
        background-size: inherit;
        background-image: url(../../../assets/images/yellow-divider-20.png);

        @media screen and (min-width: 820px) {
            margin: 0 -50px;
        }
    }
}

.progress-pink {
    h3 {
        color: #818181;
        font-size: calc(13px + 0.7vw) !important;
    }
    .progress-bar {
        background-color: $pink !important;
    }
}

.modal-message {
    .modal-header {
        // display: none;
        border-bottom: 0;
        padding-bottom: 0;
    }

    .modal-body {
        // text-align: center;
        padding-bottom: 60px;
    }

    .modal-footer {
        display: none;
    }
}

.modal-referral-form {

    .modal-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: #ffd42b;
        padding: 25px 21px;

        .modal-title {
            font-weight: 500;
            font-size: calc(16px + 0.7vw) !important;
            color: #ffffff !important;
        }
    }
}

.referral-form, .modal-referral-form {
    .btn {
        color: #F3F6F9 !important;
        font-size: calc(13px + 0.7vw) !important;
        border-radius: 0;

        &.btn-primary, &.btn-secondary {
            background-color: #fe0072;
            border-color: #fe0072;
        

            &:hover, &:active, &:focus {
                background-color: #b32163 !important;
            }
        }
    }
    
    .form-group {
        label {
            color: #818181;
            font-size: calc(13px + 0.7vw) !important;
            word-break: break-word;
        }

        .form-control {
            border-radius: 0;
            border-color: #ebebeb;
            background-color: #f8f8f8;
            color: #818181;
            padding: 8px 6px;
            outline: none;
            font: 1.7em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
            height: auto;

            &:invalid, &.is-invalid {
                border-color: #F64E60;
                background-color: #ffeaec;
            }
        }

        .select-dropdown {
            @media screen and (min-width: 820px) {
                .select-toggle, .select-options-container > .select-option {
                    font: 1.7em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
                }
            }
            .select-toggle {
                min-height: 45px;
                border-radius: 0;
                color: #818181;
                border-color: #ebebeb;
                background-color: #f8f8f8;
            }
            .select-options-container > .select-option.selected, .select-options-container > .select-option.navigating, .select-options-container > .select-option:hover {
                background-color: $pink;
                color: #fff;
            }

            &.is-invalid {
                .select-toggle {
                    border-color: #F64E60;
                    background-color: #ffeaec;
                }
            }
        }

        .auto-complete-select {
            .css-yk16xz-control, .css-1pahdxg-control {
                border-color: #e3e6ef;
                border-radius: 0;
                background-color: #f8f8f8;
                padding: 3px 0;
            }

            @media screen and (min-width: 820px) {
                .css-yk16xz-control, .css-1pahdxg-control {
                    font: 1.7em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
                }

                .css-26l3qy-menu {
                    .css-4ljt47-MenuList {
                        .css-yt9ioa-option, .css-1n7v3ny-option {
                            font: 1.7em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
                        }
                    }
                }
            }

            .css-26l3qy-menu {
                .css-4ljt47-MenuList {
                    .css-1n7v3ny-option {
                        background-color: $pink;
                        color: #fff;
                    }
                }
            }
        }

        &:not(.custom-switch) .custom-control-label::before, &:not(.custom-switch) .custom-control-label::after {
            top: 2px;
            @media screen and (min-width: 820px) {
                width: 2rem;
                height: 2rem;
            }
        }

        .custom-control-label {
            font-size: calc(10px + 0.7vw) !important;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            color: #ffffff;
            border-color: #fe0072;
            background-color: #ff5c99;
            box-shadow: none;
        }

        
    }

    .react-datepicker-popper {
        &[data-placement^="top"] .react-datepicker__triangle::before, &[data-placement^="top"] .react-datepicker__triangle::after {
            border-top-color: $pink !important;
        }
        &[data-placement^="bottom"] .react-datepicker__triangle::before, &[data-placement^="bottom"] .react-datepicker__triangle::after {
            border-bottom-color: $pink !important;
        }
    }

    .react-datepicker {
        font-family: "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 1.2rem;

        .react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
            background-color: $pink;
            color: #fff;
        }

        button.react-datepicker__navigation {
            top: 20px;
            border: 10px solid transparent;
            height: 20px;
            width: 20px;
            outline: 0;
    
            &.react-datepicker__navigation--previous {
                border-right-color: #ccc;
                border-radius: 5px;
            }
    
            &.react-datepicker__navigation--next {
                border-left-color: #ccc;
                border-radius: 5px;
            }
        }

        .react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
            padding: 6px;
        }

        .react-datepicker__navigation--years-upcoming {
            border-bottom-color: $pink;
        }

        .react-datepicker__navigation--years-previous {
            border-top-color: $pink;
        }

        .react-datepicker__year-read-view--selected-year {
            color: #fff;
        }

        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
            font-size: 1.344rem;
        }

        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
            background-color: $pink;
        }

        .react-datepicker__header {
            background-color: $pink;
        }

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            width: 3.5rem !important;
            line-height: 3.5rem !important;

            @media screen and (min-width: 820px) {
                width: 4.5rem !important;
                line-height: 4.5rem !important;
            }
        }
    }
}