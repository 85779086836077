#quick-time-logs {
    .table {
        thead {
            th:last-child {
                width: 75px;
            }
        }

        tbody {
            td {
                .time-log-task-title {
                    word-break: break-word;
                }

                .badge {
                    margin-top: 5px;
                }
            }
        }
    }

    
}