.stop-watch {
    display: flex;
    min-width: 80px;
    height: 23px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    
    &-container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
    }
}