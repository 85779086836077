#time-tracker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .task-description {
        // max-width: 400px;
        border-color: transparent;

        &.is-invalid {
            border-color: #F64E60;
        }

        margin-right: 5px;
    }

    .select-dropdown {
        max-width: 200px;
        
        .select-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
        }
    }

    .btn-rounded {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        padding: 11px;

        i {
            font-size: 17px !important;
        }

        &.btn-mini {
            width: 16px;
            height: 16px;
            padding: 4px;

            i {
                font-size: 8px !important;
            }
        }
    }

    .custom-checkbox .custom-control-label::before {
        border: 1px solid #7e8299;
    }

    .time-tracker-modes {
        display: flex;
        flex-direction: column;
        padding: 3px;
        background-color: #eee;
        border-radius: 15px;
        height: 100%;
        margin: -3px 0;
        margin-left: 5px;
    }
    
    .time-tracker-actions {
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;

        .time-tracker-time-range {
            display: flex;
            // width: 200px;

            .custom-time-field {
                width: 110px;
                padding-left: 5px;
                padding-right: 5px;
                margin-right: 5px;
            }

            .react-datepicker-wrapper  {
                width: 110px;
                margin-right: 5px;
            }
        }
    }
}